export default function loanstate({ to, next, store }) {
  const loanSubmitted = store.getters['auth/loanSubmitted']
  const loanFormState = store.getters['auth/loanFormState']

  const routeName = to.name

  if ((loanSubmitted === true || loanSubmitted === 'true') && routeName !== 'home') {
    return next({ name: 'home' })
  }

  if (loanSubmitted === 'false' || loanSubmitted === false) {
    // if ((loanFormState === null || loanFormState === 'null') && routeName !== 'personal-detail') {
    //   return next({ name: 'personal-detail' })
    // } else if (loanFormState === 'personal' && routeName !== 'employment') {
    //   return next({ name: 'employment' })
    // } else if (loanFormState === 'employment' && routeName !== 'financial') {
    //   return next({ name: 'financial' })
    // } else if (loanFormState === 'financial' && routeName !== 'file-upload') {
    //   return next({ name: 'file-upload' })
    // } else if (loanFormState === 'documents' && routeName !== 'interview-appointment') {
    //   return next({ name: 'interview-appointment' })
    // }
    if ((loanFormState === null || loanFormState === 'null') && routeName !== 'customer-information') {
      return next({ name: 'qr-scan' })
    } else if (loanFormState === 'customer_information' && routeName !== 'required-documents') {
      return next({ name: 'required-documents' })
    } else if (loanFormState === 'required_documents' && routeName !== 'additional-information') {
      return next({ name: 'additional-information' })
    } else if (loanFormState === 'additional_information' && routeName !== 'interview-appointment') {
      return next({ name: 'interview-appointment' })
    }

    return next()
  }

  return next()
}
