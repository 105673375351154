<template>
  <div id="app">
    <cookie-law class="p-0 bg-dialog">
      <div slot-scope="props" class="text-center d-flex align-items-start flex-column vh-100 m-0 p-0">
        <div class="mt-auto bg-white p-4 vw-100">
          <h5 class="text-primary">{{ $t('cookie_permission') }}</h5>
          <b-button class="mt-4 px-4" pill variant="primary" @click="props.accept">{{ $t('accep_cookie') }}</b-button>
        </div>
      </div>
    </cookie-law>
    <messenger></messenger>
    <component :is="layout"></component>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import Cookies from 'js-cookie'

import Messenger from './library/Messenger.vue'

export default {
  name: 'App',
  components: {
    CookieLaw,
    LayoutFull,
    LayoutDefault,
    Messenger,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'default') return 'layout-default'
      return 'layout-full'
    },
  },
  beforeCreate() {
    if (process.env.VUE_APP_ENVIROMENT !== 'local') {
      this.$OneSignal.showSlidedownPrompt()
      this.$OneSignal.getUserId(function (userId) {
        Cookies.set('onesignal_user_id', userId)
      })
    }
  },
}
</script>

<style></style>
